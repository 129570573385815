<template>
    <div class="footer">
        <div class="content ">
            <div class="Between">
                <div class="engineering_class flex">
                    <div class="footer_Level_list">
                        <div class="label">服务范围</div>
                        <div class="Level_list">
                            <div class="list pointer" v-for="(item, index) in $store.state.serviceType" :key="index"
                                @click="jump(item, 2, index)">
                                {{ item.label }}
                            </div>
                        </div>
                    </div>
                    <div class="footer_Level_list">
                        <div class="label">解决方案</div>
                        <div class="Level_list">
                            <div class="list pointer" v-for="(item, index) in $store.state.solution" :key="index"
                                @click="jump(item, 3, index)">
                                {{ item.label }}
                            </div>
                        </div>
                    </div>
                    <div class="footer_Level_list">
                        <div class="label">工程案例</div>
                        <div class="Level_list">
                            <div class="list pointer" v-for="(item, index) in $store.state.projectCase" :key="index"
                                @click="jump(item, 4, index)">
                                {{ item.label }}
                            </div>
                        </div>
                    </div>
                    <div class="footer_Level_list">
                        <div class="label">产品中心</div>
                        <div class="Level_list">
                            <div class="list pointer" v-for="(item, index) in $store.state.goodsData" :key="index"
                                @click="jump(item, 5, index)">
                                {{ item.label }}
                            </div>
                        </div>
                    </div>
                    <div class="footer_Level_list">
                        <div class="label">新闻资讯</div>
                        <div class="Level_list">
                            <div class="list pointer" v-for="(item, index) in $store.state.journalism" :key="index"
                                @click="jump(item, 6, index)">
                                {{ item.label }}
                            </div>
                        </div>
                    </div>
                    <div class="footer_Level_list">
                        <div class="label">关于我们</div>
                        <div class="Level_list">
                            <div class="list pointer" v-for="(item, index) in $store.state.aboutUsList" :key="index"
                                @click="jump(item, 7, index)">
                                {{ item.label }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="focus_GEJ display column">
                    <img class="logo" :src="url" alt="">
                    <div class="flex-center qrCodeBox">
                        <div class="qr_code display column">
                            <img class="icon_code" src="@/assets/img/homes/qrCode.png" alt="">
                            <div class="name">扫码关注公众号</div>

                        </div>
                        <div class="qr_code display column">
                            <img class="icon_code"
                                src="https://www.golkia.com/admin/profile/upload/2024/11/15/4d220b1f-4ade-4c7c-9499-0b18f361b180.png"
                                alt="">
                            <div class="name">建筑维修工程师微信号</div>

                        </div>
                    </div>
                    <div class="servicePhone flex-center">服务监督电话：<p class="phone">400-698-3335</p>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="blogroll display">
                <div class="link_div">友情链接 :</div>
                <div class="list pointer" @click="blogrollBtn(item.url)" v-for="(item, index) in blogroll" :key="index">
                    {{ item.name }}
                </div>
            </div>
            <div class="record display pointer" @click="toLink">
                Copyright © 2006 - 2028 Golkia. All Rights Reserved. 果尔佳公司 版权所有 粤ICP备19121115号
            </div>
        </div>
        <!-- <div class="makeAppointment" v-if="isShow && applyShow">
            <div class="maskLayer"></div>
            <div class="formData display column">
                <div class="hint display">
                    <div class="default_div">免费</div>
                    <div class="red_div">上门勘察</div>
                    <div class="dot"></div>
                    <div class="default_div">免费</div>
                    <div class="red_div">定制方案</div>
                    <div class="dot"></div>
                    <div class="red_div">产品、施工、售后</div>
                    <div class="default_div">一站式</div>
                    <div class="dot"></div>
                    <div class="default_div">一质保十年、</div>
                    <div class="red_div">省钱省力</div>
                </div>
                <div class="formData_div flex">
                    <div class="list display">
                        <input type="text" placeholder="请输入公司名称" v-model="makeAppointment.companyName">
                    </div>
                    <div class="list list_city display">
                        <el-cascader size="large" :options="options" :props="props" v-model="AreaArray"
                            @change="selectArea" placeholder="请选择所在城市" clearable></el-cascader>
                    </div>
                    <div class="list list_city list_type display">
                        <el-select clearable placeholder="需求类型" @change="selectType"
                            v-model="makeAppointment.categoryName">
                            <el-option v-for="item in $store.state.serviceType" :key="item.value" :label="item.label"
                                :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="list list_name display">
                        <input type="text" placeholder="请输入姓名" v-model="makeAppointment.userName">
                    </div>
                    <div class="list list_phone display">
                        <input type="text" maxlength="11" placeholder="请输入手机号" v-model="makeAppointment.phoneNumber">
                    </div>
                    <div class="btn display pointer" @click="signUp">立即预约</div>
                    <img class="icon_close pointer" @click="isShow = false" src="@/assets/img/homes/close.png" alt="">
                </div>

            </div>
        </div> -->
    </div>
</template>
<script>
import areaJSON from "@/assets/js/area.json";
export default {
    props: {
        url: {}
    },
    data() {
        return {
            isShow: true,
            applyShow: true,
            options: areaJSON,
            AreaArray: [],
            value: '',
            props: {
                children: "children",
                label: "name",
                value: "name",
            },
            blogroll: [
                {
                    id: 1,
                    name: '果尔佳集团',
                    url: 'http://www.golkia.com/'
                },
                {
                    id: 2,
                    name: '果尔佳商城',
                    url: 'https://www.golkia-mall.com/'
                },
                {
                    id: 3,
                    name: '果尔佳建筑产业',
                    url: 'http://www.golkia.net/'
                },
                {
                    id: 3,
                    name: '果尔佳服务',
                    url: 'https://www.golkia-mall.com/#/serviceSystem/home'
                },
            ],
            makeAppointment: {
                serviceAreaCategoryId: "",
                categoryName: "",
                userName: "",
                phoneNumber: "",
                companyName: "",
                province: "",
                city: "",
                district: "",
                detailAddress: 0,
                detailedRequirements: 0
            },
            footerLevelList: [
                {
                    id: 2,
                    name: "服务范围",
                    url: ""
                },
            ],
            aboutUs: [
                {
                    id: 1,
                    label: "公司简介"
                },
                {
                    id: 2,
                    label: '发展历程',
                },
                {
                    id: 3,
                    label: '荣誉资质',
                },
                {
                    id: 4,
                    label: '企业文化',
                }
            ]
        }
    },
    // 监听路由变化
    // watch: {
    //     $route: "urlName",
    // },
    mounted() {
        // let url = window.location.href
        // if (url.indexOf("/index")>= 0){
        //     this.applyShow = true
        // }else{
        //     this.applyShow = false
        // }
        this.getServiceType()
        this.getFindOneSolution()
        this.getProjectCaseCategory()
        this.getCassification()
    },
    methods: {
        // 监听url参数变化
        // urlName() {
        //     let url = window.location.href
        //     if (url.indexOf("/index") >= 0) {
        //         this.applyShow = true
        //     } else {
        //         this.applyShow = false
        //     }
        // },
        //获取服务类型
        getServiceType() {
            this.api.findServiceAreaCategory().then(res => {
                res.data.forEach(item => {
                    item.label = item.categoryName
                    item.value = item.id
                })
                this.classifyList = res.data
                this.$store.commit("getServiceType", res.data)
            })
        },
        //获取解决方案分类
        getFindOneSolution() {
            this.api.findOneSolution().then(res => {
                res.data.forEach(item => {
                    item.label = item.solutionName
                })
                this.$store.commit("getSolution", res.data)
            })
        },
        //获取工程案例分类
        getProjectCaseCategory() {
            this.api.findProjectCaseCategory().then(res => {
                res.data.forEach(item => {
                    item.label = item.categoryName
                    item.name = item.categoryName
                })
                this.$store.commit('getProjectCase', res.data)
            })
        },
        //获取商品分类
        getCassification() {
            this.api.findGoodsClassification().then(res => {
                this.goodsClassification = res.data
                this.$store.commit("getGoodsData", res.data)
            })
        },
        // 点击友情链接
        blogrollBtn(url) {
            window.open(url, '_blank');
        },
        toLink() {
            window.open('https://beian.miit.gov.cn/', '_blank');
        },
        // 选择省市区
        selectArea(val) {
            this.makeAppointment.province = val[0];
            this.makeAppointment.city = val[1];
            this.makeAppointment.district = val[2];
        },
        //选择服务呢类型分类
        selectType(val) {
            console.log(val);
            this.makeAppointment.categoryName = val.label
            this.makeAppointment.serviceAreaCategoryId = val.value
        },
        // 立即报名
        signUp() {
            if (this.makeAppointment.companyName == '') {
                return this.$message.error('请输入公司名称')
            }
            if (this.makeAppointment.province == '') {
                return this.$message.error('请选择所在城市')
            }
            if (this.makeAppointment.categoryName == '') {
                return this.$message.error('请选择需求类型')
            }
            if (this.makeAppointment.userName == '') {
                return this.$message.error('请输入姓名')
            }
            if (!/^[0-9]{11}$/.test(this.makeAppointment.phoneNumber)) {
                return this.$message.error('请正确输入手机号')
            }
            this.api.addReserve(this.makeAppointment).then(res => {
                if (res.code == 0) {
                    this.$message.success('提交成功')
                    this.makeAppointment = {
                        serviceAreaCategoryId: "",
                        categoryName: "",
                        userName: "",
                        phoneNumber: "",
                        companyName: "",
                        province: "",
                        city: "",
                        district: "",
                        detailAddress: 0,
                        detailedRequirements: 0
                    }
                    this.AreaArray = []
                }
            })
        },
        //点击分类跳转
        jump(item, id, index) {
            let url = ''
            let fatherName = ""
            if (id == 2) {
                url = '/scopeServices'
            } else if (id == 3) {
                url = '/solution'
                fatherName = item.solutionName
            } else if (id == 4) {
                url = '/projectCase'
                item.index = index
                localStorage.setItem('navBarData', JSON.stringify(item))
            } else if (id == 5) {
                url = '/productCenter'
            } else if (id == 6) {
                url = '/news/index'
            }
            else if (id == 7) {
                url = '/aboutUs'
            }
            let obj = {
                ids: id,
                id: id != 3 ? item.id : item.solutionVOS[0].id,
                name: id != 3 ? item.label : item.solutionVOS[0].solutionName,
                index: index,
                fatherName: fatherName,
                isReturn: 3
            }
            let typeId = id != 3 ? item.id : item.solutionVOS[0].id
            this.$store.commit("getTypeId", typeId)
            localStorage.setItem('navBarData', JSON.stringify(obj))
            let routeData = this.$router.resolve({
                path: url,
                query: {
                    // ids: id,
                    id: id != 3 ? item.id : item.solutionVOS[0].id,
                    // name: id != 3 ? item.label : item.solutionVOS[0].solutionName,
                    // index: index,
                    // fatherName: fatherName,
                    // isReturn: 3
                }
            });
            window.open(routeData.href, '_blank');
        },
    }
}
</script>
<style lang="scss" scoped>
.footer {
    height: 560px;
    background: #2c2c2c;
    // background: url('../assets/img/home/footbg.png');
    background-size: 100% 100%;
    padding-top: 50px;

    .engineering_class {
        min-width: 850px;

        .footer_Level_list {
            width: 140px;
            padding-left: 26px;
            box-sizing: border-box;
            border-left: 1px solid #3b3b3b;
            border-right: 1px solid #3b3b3b;

            .label {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FAFAFA;
                line-height: 18px;
            }

            .Level_list {
                margin-top: 20px;

                .list {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #A7A7A7;
                    line-height: 16px;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .focus_GEJ {
        margin-right: 5px;
        margin-left: 0px;

        .logo {
            width: 268px;
            height: 66px;
        }

        .qr_code {
            margin: 0 10px;
            margin-top: 25px;

            .icon_code {
                width: 156px;
                height: 156px;
            }

            .name {
                font-size: 15px;
                color: #888888;
                margin-top: 15px;


            }

        }

        .servicePhone {
            font-size: 18px;
            color: #fff;
            margin-top: 15px;
            line-height: 16px;

            .phone {
                font-size: 22px;
                font-family: Arial;
                font-weight: 700;
                line-height: 22px;
                color: #e62129;
            }
        }
    }

    .line {
        width: 1200px;
        height: 1px;
        background: #3e3e3e;
        margin-top: 34px;
        margin-bottom: 30px;
    }

    .blogroll {
        margin-top: 27px;

        div {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #C3C3C3;
            line-height: 12px;

        }

        .list {
            border-right: 1px solid #b0b0b0;
            padding: 0 10px;
        }

        .list:last-child {
            border-right: none;
        }
    }

    .record {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #C3C3C3;
        line-height: 12px;
        margin-top: 16px;
    }

    .makeAppointment {
        width: 100%;
        height: 152px;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 999;

        .maskLayer {
            width: 100%;
            height: 152px;
            background: #000;
            opacity: 0.6;
            position: absolute;
            left: 0;
            bottom: 0;

        }

        .hint {
            margin-top: 30px;
            font-size: 28px;

            .default_div {
                color: #fff;
            }

            .red_div {
                color: #E62129;
            }

            .dot {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: #fff;
                margin: 0px 14px;
                margin-top: 5px;
            }
        }

        .formData {
            width: 1200px;
            margin: 0 auto;
            border-radius: 12px 12px 0 0;
            position: relative;

            .formData_div {
                margin-top: 16px;
            }

            .icon_close {
                width: 44px;
                height: 44px;
                position: absolute;
                top: -35px;
                right: 8px;
            }

            .list {
                width: 160px;
                height: 34px;
                background: #FFFFFF;
                border: 1px solid #DEDEDE;
                box-sizing: border-box;
                margin-right: 10px;

                input {
                    width: 100%;
                    height: 30px;
                    padding-left: 10px;
                }
            }

            .list_city {
                width: 180px !important;

                ::v-deep.el-cascader__dropdown {
                    z-index: 100000 !important;
                }

                ::v-deep.el-input__inner {
                    width: 170px !important;
                    height: 28px !important;
                    border: none !important;
                }
            }

            .list_type {
                ::v-deep.el-input__suffix {
                    top: 6px;
                }
            }

            .list_name {
                width: 120px !important;
            }

            .list_phone {
                width: 150px !important;
            }

            .btn {
                width: 140px;
                height: 34px;
                background: #E62129;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}
</style>